import { inject }            from 'aurelia-framework';
import { PLATFORM }          from 'aurelia-pal';
import { BaseFormViewModel } from 'base-form-view-model';
import { FormSchema }        from 'modules/management/dynamic-fields/association-sectors/form-schema';
import { FieldsRepository }  from 'modules/management/dynamic-fields/association-sectors/services/fields-repository';
import { Sector }            from 'modules/management/models/sector';
import { SectorsRepository } from 'modules/management/specifications/sectors/services/repository';
import { AppContainer }      from 'resources/services/app-container';

@inject(AppContainer, SectorsRepository, FieldsRepository, FormSchema)
export class EditAssociationSector extends BaseFormViewModel {

    headerTitle = 'form.title.edit-record';
    formId      = 'management.dynamic-fields.association-sectors.edition-form';

    alert  = {};
    model  = {};
    schema = {};

    constructor(appContainer, repository, fieldsRepository, formSchema) {
        super(appContainer);

        this.repository       = repository;
        this.fieldsRepository = fieldsRepository;
        this.formSchema       = formSchema;
    }

    /**
     * Handles canActivate event
     */
    canActivate(params) {
        this.category = params.category;
        this.id       = params.id;

        this.fieldsRepository.setSectorId(this.id);

        let fieldSlugs = Sector.getFieldSlugs(this.id);

        this.IN_SITU_WORK_CONTROL          = fieldSlugs.includes(Sector.IN_SITU_WORK_CONTROL);
        this.LAB_WORK_CONTROL              = fieldSlugs.includes(Sector.LAB_WORK_CONTROL);
        this.LAB_USE_IN                    = fieldSlugs.includes(Sector.LAB_USE_IN);
        this.LAB_MATERIAL_CHARACTERIZATION = fieldSlugs.includes(Sector.LAB_MATERIAL_CHARACTERIZATION);

        this.slugs = {
            IN_SITU_WORK_CONTROL:          Sector.IN_SITU_WORK_CONTROL,
            LAB_WORK_CONTROL:              Sector.LAB_WORK_CONTROL,
            LAB_USE_IN:                    Sector.LAB_USE_IN,
            LAB_MATERIAL_CHARACTERIZATION: Sector.LAB_MATERIAL_CHARACTERIZATION,
        };

        return super.can([
            'management.dynamic-fields.association-sectors.manage',
            'management.dynamic-fields.association-sectors.edit',
        ]);
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/management/dynamic-fields/association-sectors/form.html');
    }

    /**
     * Handles activate event
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async activate(params) {
        super.activate();

        this.schema       = this.formSchema.schema(this);
        this.globalSchema = this.formSchema.globalSchema(this);
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return await this.fetchData(params);
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    submit() {
        this.alert = null;

        this.repository
            .update(this.model.id, this.model)
            .then((response) => {
                this.handleResponse(response, {
                    name:   'management.dynamic-fields.association-sectors.index',
                    params: {
                        category: this.category,
                    },
                });
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async fetchData(params) {
        const response = await this.repository.find(params.id);
        this.initialModel.assign(response);
        this.model.assign(response);
    }
}
