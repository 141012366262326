import { bindable, bindingMode, customElement, inject } from 'aurelia-framework';
import { BaseViewModel }                                from 'base-view-model';
import { FormSchema }                                   from 'modules/management/dynamic-fields/association-sectors/sectors-fields/form-schema';
import { Sector }                                       from 'modules/management/models/sector';
import { AppContainer }                                 from 'resources/services/app-container';

@customElement('associate-fields')
@inject(AppContainer, FormSchema)
export class AssociateFields extends BaseViewModel {

    rand      = Math.floor(Math.random() * 65000)
    listingId = 'fields-listing-' + this.rand;
    formId    = 'fields-creation-form-' + this.rand;

    @bindable({ defaultBindingMode: bindingMode.twoWay }) readonly = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) relatableModel;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) relatableRepository;
    @bindable slug;

    datatable;
    creationSchemaVisible = false;

    /**
     * Constructor
     *
     * @param appContainer
     * @param formSchema
     */
    constructor(appContainer, formSchema) {
        super(appContainer);

        this.formSchema = formSchema;
    }

    /**
     * Handles activate event
     */
    bind() {
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();
        this.schema       = this.formSchema.schema(this);

        this.legend = 'form.title.' + this.slug.replaceAll('_', '-');

        this.defineDatatable();
    }

    /**
     * Defines datatable
     */
    defineDatatable() {
        this.datatable = {
            resource:        'resource.management.dynamic-fields.association-sectors.fields',
            repository:      {
                search:          (criteria) => this.relatableRepository.search({ slug: this.slug, ...criteria }),
                destroy:         (id) => this.relatableRepository.destroy(id),
                destroySelected: (ids) => this.relatableRepository.destroySelected(ids),
            },
            destroy:         !this.readonly,
            destroySelected: !this.readonly,
            options:         [],
            buttons:         [
                {
                    label:     'form.button.create-new',
                    icon:      'icon-plus3',
                    className: 'btn bg-success',
                    color:     'success',
                    visible:   !this.readonly,
                    action:    () => this.creationSchemaVisible = true,
                },
            ],
            sorting:         {
                column:    0,
                direction: 'desc',
            },
            selectable:      !this.readonly,
            columns:         this.columns(),
        };
    }

    /**
     * Submits view form
     */
    submit() {
        this.alert = null;

        this.model.slug = this.slug;

        // calls repository create method
        return this.relatableRepository.create(this.model)
            .then((response) => {
                this.alert = this.alertMessage(response.status, response.message, response.errors);

                if (response.status === true) {
                    this.datatable.instance.reload();
                    this.resetForm();
                }
            }).catch(console.error.bind(console));
    }

    /**
     * Resets form fields
     */
    resetForm(nullifyAlert = true) {
        this.resetModelValues()
            .then(() => {
                // publishes `form-reseted` event
                this.appContainer.eventAggregator.publish('form-reseted', this.formId);

                if (nullifyAlert) {
                    this.alert = null;
                }
            });
    }

    /**
     * Resets model to initial values
     *
     * @returns {Promise}
     */
    resetModelValues() {
        return new Promise((resolve, reject) => {
                this.model.assign(this.initialModel);
                resolve(true);
                reject(new Error('Error'));
            },
        );
    }

    columns() {

        let performedTest       =
                {
                    data:  'performed_test_name',
                    name:  'performed_test_translations.name',
                    title: 'form.field.performed-test',
                };
        let sampleCollection    =
                {
                    data:  'sample_collection_name',
                    name:  'sample_collection_translations.name',
                    title: 'form.field.sample-collection',
                };
        let partZoneApplication =
                {
                    data:  'part_zone_application_name',
                    name:  'part_zone_application_translations.name',
                    title: 'form.field.part-zone-application',
                };
        let layer               =
                {
                    data:  'layer_name',
                    name:  'manage_layer_translations.name',
                    title: 'form.field.layer',
                };
        let usage               =
                {
                    data:  'usage_name',
                    name:  'usage_translations.name',
                    title: 'form.field.usage',
                };

        switch (this.slug) {
            case Sector.IN_SITU_WORK_CONTROL:
                return [performedTest, partZoneApplication, layer];
            case Sector.LAB_WORK_CONTROL:
                return [sampleCollection, partZoneApplication, layer];
            case Sector.LAB_USE_IN:
                return [sampleCollection, partZoneApplication, usage];
            case Sector.LAB_MATERIAL_CHARACTERIZATION:
                return [sampleCollection];
        }
    }

}
